import AnalyticsMetricAnomalies from './strings/AnalyticsMetricAnomalies/AnalyticsMetricAnomalies.json';
import CalculatedMetric from './strings/Cards/CalculatedMetric/CalculatedMetric.json';
import EditCalculatedMetric from './strings/Cards/CalculatedMetric/Edit/EditCalculatedMetric.json';
import Cards from './strings/Cards/Cards.json';
import NoteCard from './strings/Cards/Note/NoteCard.json';
import EditDimensionTimeserie from './strings/Cards/Timeserie/Edit/EditDimensionTimeserie.json';
import EditVisitMetricTimeseries from './strings/Cards/Timeserie/Edit/EditVisitMetricTimeseries.json';
import Title from './strings/Cards/Title/Title.json';
import Trend from './strings/Cards/Trend/Trend.json';
import VisitMetric from './strings/Cards/VisitMetric/VisitMetric.json';
import DateChart from './strings/Chart/DateChart/DateChart.json';
import CardLegend from './strings/Chart/Legend/CardLegend.json';
import CreateReportMenu from './strings/CreateReportMenu.json';
import Dashboard from './strings/Dashboard/Dashboard.json';
import Tab from './strings/Dashboard/Tab.json';
import DataHealth from './strings/DataHealth/DataHealth.json';
import DeleteReport from './strings/DeleteReport.json';
import Dimensions from './strings/Dimensions/Dimensions.json';
import Explorer from './strings/Explorer/Explorer.json';
import Export from './strings/Exports/Export.json';
import ExportSchedule from './strings/Exports/Schedules/ExportSchedule.json';
import Filter from './strings/Filters/Filter.json';
import NamedFilters from './strings/Filters/Named/NamedFilters.json';
import SaveAsNamedFilter from './strings/Filters/Named/SaveAsNamedFilter.json';
import Groups from './strings/Filters/Permission/Access/Groups/Groups.json';
import Users from './strings/Filters/Permission/Access/Users/Users.json';
import PermissionsFilters from './strings/Filters/Permission/PermissionsFilters.json';
import PredefinedFilters from './strings/Filters/Predefined/PredefinedFilters.json';
import MetricDisplayNames from './strings/MetricDisplayNames.json';
import Metrics from './strings/Metrics/Metrics.json';
import Privileges from './strings/Privileges/Privileges.json';
import RawData from './strings/RawData/RawData.json';
import ReportAccess from './strings/Reports/Access/ReportAccess.json';
import ReportsExport from './strings/Reports/Export/Export.json';
import Import from './strings/Reports/Import/Import.json';
import Report from './strings/Reports/Report.json';
import Templates from './strings/Reports/Template/Templates.json';
import TransferReportContent from './strings/Reports/Transfer/TransferReportContent.json';
import ReportsListTable from './strings/ReportsListTable.json';
import InternalIP from './strings/Settings/InternalIP/InternalIP.json';
import Limits from './strings/Settings/Limits/Limits.json';
import Settings from './strings/Settings/Settings.json';
import StrictValidation from './strings/Settings/StrictValidation/StrictValidation.json';
import Strings from './strings/Strings.json';
import VisitBrowserChord from './strings/VisitBrowser/Chord/VisitBrowserChord.json';
import VisitBrowser from './strings/VisitBrowser/VisitBrowser.json';

export const StringMap: Record<string, Record<string, any>> = {
    'strings/AnalyticsMetricAnomalies/AnalyticsMetricAnomalies': AnalyticsMetricAnomalies,
    'strings/Cards/CalculatedMetric/CalculatedMetric': CalculatedMetric,
    'strings/Cards/CalculatedMetric/Edit/EditCalculatedMetric': EditCalculatedMetric,
    'strings/Cards/Cards': Cards,
    'strings/Cards/Note/NoteCard': NoteCard,
    'strings/Cards/Timeserie/Edit/EditDimensionTimeserie': EditDimensionTimeserie,
    'strings/Cards/Timeserie/Edit/EditVisitMetricTimeseries': EditVisitMetricTimeseries,
    'strings/Cards/Title/Title': Title,
    'strings/Cards/Trend/Trend': Trend,
    'strings/Cards/VisitMetric/VisitMetric': VisitMetric,
    'strings/CreateReportMenu': CreateReportMenu,
    'strings/Dashboard/Dashboard': Dashboard,
    'strings/Dashboard/Tab': Tab,
    'string/DeleteReport': DeleteReport,
    'strings/Dimensions/Dimensions': Dimensions,
    'strings/Explorer/Explorer': Explorer,
    'strings/Exports/Export': Export,
    'strings/Exports/Schedules/ExportSchedule': ExportSchedule,
    'strings/Filters/Filter': Filter,
    'strings/Filters/Named/NamedFilters': NamedFilters,
    'strings/Filters/Named/SaveAsNamedFilter': SaveAsNamedFilter,
    'strings/Filters/Permission/Access/Groups/Groups': Groups,
    'strings/Filters/Permission/Access/Users/Users': Users,
    'strings/Filters/Permission/PermissionsFilters': PermissionsFilters,
    'strings/Filters/Predefined/PredefinedFilters': PredefinedFilters,
    'strings/MetricDisplayNames': MetricDisplayNames,
    'strings/Metrics/Metrics': Metrics,
    'strings/Privileges/Privileges': Privileges,
    'strings/RawData/RawData': RawData,
    'strings/Reports/Access/ReportAccess': ReportAccess,
    'strings/Reports/Export/Export': ReportsExport,
    'strings/Reports/Import/Import': Import,
    'strings/Reports/Report': Report,
    'strings/Reports/Template/Templates': Templates,
    'strings/Reports/Transfer/TransferReportContent': TransferReportContent,
    'strings/ReportsListTable': ReportsListTable,
    'strings/Settings/InternalIP/InternalIP': InternalIP,
    'strings/Settings/Limits/Limits': Limits,
    'strings/Settings/Settings': Settings,
    'strings/Settings/StrictValidation/StrictValidation': StrictValidation,
    'strings/Strings': Strings,
    'strings/VisitBrowser/Chord/VisitBrowserChord': VisitBrowserChord,
    'strings/VisitBrowser/VisitBrowser.json': VisitBrowser,
    'strings/DataHealth/DataHealth.json': DataHealth,
    'strings/Chart/Legend/CardLegend': CardLegend,
    'strings/Chart/DateChart/DateChart': DateChart,
};
