import {Locales} from '../../../Locales';

export const SharePointOnline2SourceConstants = {
    Components: {
        SourceNameInput: 'sharepointonline2-name-input',
        TenantNameLabel: 'tenant-name-label',
        CrawlingAccountLabel: 'crawling-account-label',
    },
    OAuth2LabelName: Locales.format('sharePointOnlineOAuth2LabelName'),
};
