import {setKeyValue} from '@core/store';
import {FileUtils} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {AdminState} from '../../../../../src/application/Reducers';

export const CertificateActionTypes = {
    setBase64Certificate: 'SET_SPO2_BASE_64_CERTIFICATE',
};

export const setCertificateInBase64 =
    (certificateFile: File): IThunkAction<void, AdminState> =>
    async (dispatch) => {
        if (certificateFile) {
            return FileUtils.readFileAsString(certificateFile, (certificateString: string) => {
                const base64Certificate = btoa(certificateString);
                dispatch(setKeyValue(CertificateActionTypes.setBase64Certificate, base64Certificate));
            });
        }

        return dispatch(setKeyValue(CertificateActionTypes.setBase64Certificate, null));
    };
