export class FileUtils {
    static readFileAsString(file: File, callback: (...args: any[]) => any) {
        const reader = new FileReader();
        reader.onload = (readerEvt: ProgressEvent) => {
            const content = (readerEvt.target as FileReader).result;
            const fileAsString = typeof content === 'string' ? content : FileUtils.arrayBufferToString(content);
            callback(fileAsString);
        };
        reader.readAsArrayBuffer(file);
    }

    static arrayBufferToString(arrayBuffer: ArrayBuffer): string {
        return String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
    }

    static download(target: string | Blob, filename: string, type = 'text/plain') {
        const url = target instanceof Blob ? URL.createObjectURL(target) : target;
        const fakeLink = document.createElement('a');
        fakeLink.href = url;
        fakeLink.setAttribute('download', filename);
        document.body.appendChild(fakeLink);
        fakeLink.click();
        setTimeout(() => {
            document.body.removeChild(fakeLink);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}
