import {SourceType} from '@core/api';
import {createSelector} from 'reselect';

import {ServiceNowSourceModel} from '../../../sources/servicenow/ServiceNowSourceSelectors';
import {ServiceNowSourceConstants} from '../../../sources/servicenow/ServiceNowSourceConstants';
import {SharePointOnline2SourceModel} from '../../../sources/sharepointOnline2/SharePointOnline2SourceSelectors';
import {SharePointOnline2SourceConstants} from '../../../sources/sharepointOnline2/SharePointOnline2SourceConstants';
import {AdminState} from '../../../../application/Reducers';

export type OAuth2StateType = {
    clientId: string;
    clientSecretGuid: string;
    oauthRefreshTokenGuid: string;
    tenantName: string;
    crawlingAccount: string;
    refreshTokenGuid: string;
};

const OAuth2InitialState: OAuth2StateType = {
    oauthRefreshTokenGuid: null,
    clientId: null,
    clientSecretGuid: null,
    tenantName: null,
    crawlingAccount: null,
    refreshTokenGuid: null,
};

const getAll = createSelector(
    (state: AdminState) => state?.oauth2Tokens,
    (oauth2Tokens: OAuth2StateType) => (_.every(oauth2Tokens, (token: string) => !!token) ? oauth2Tokens : {}),
);

const getServiceNowOAuth2ClientId = (state: AdminState) => ({
    name: ServiceNowSourceConstants.OAuth2LabelName,
    value: (state.editSource as ServiceNowSourceModel)?.clientId || state.oauth2Tokens?.clientId,
});
const getSharePointOnlineOAuth2CrawlerAccount = (state: AdminState) => ({
    name: SharePointOnline2SourceConstants.OAuth2LabelName,
    value: (state.editSource as SharePointOnline2SourceModel)?.crawlingAccount || state.oauth2Tokens?.crawlingAccount,
});

const getOAuth2ConnectionLabel = {
    [SourceType.SERVICENOW]: getServiceNowOAuth2ClientId,
    [SourceType.SHAREPOINT_ONLINE2]: getSharePointOnlineOAuth2CrawlerAccount,
};

export {getOAuth2ConnectionLabel, getAll, OAuth2InitialState};
